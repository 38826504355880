<template>
    <Good withoutSearch/>
</template>

<script>

import Good from '../components/Good.vue'

export default {
    components: {
        Good
    },
}
</script>

